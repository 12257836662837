import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class RealtimeDbService {

  constructor(private db: AngularFireDatabase) { }

  async pushData(object, data) {
    try {
      const ref = this.db.list(object);
      const result = (await ref.push(data)).key
      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  async setData(object, data) {
    try {
      const ref = this.db.object(object);
      // desctructive
      const result = await ref.set(data);
      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  async updateData(object, data) {
    try {
      const ref = this.db.object(object);
      const result = await ref.update(data);
      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  async unsetData(object) {
    try {
      if (!object) {
        throw new Error('!object')
      }
      const ref = this.db.object(object);
      const result = await ref.remove();
      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  updateDataSync(object, data) {
    const ref = this.db.object(object);
    return ref.update(data).then(result=>{
      return result
    });
  }

  async findByChild(equalTo, object, orderby, once) {
    try {
      const ref = this.db.object(object);
      const result = await ref.query.orderByChild(orderby).equalTo(equalTo).once(once)
      if (result.exists) {
        return result.val();
      } else {
        return null
        // throw new Error('data not found')
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  async findByChildLimit(equalTo, object, orderby, once, limit) {
    try {
      const ref = this.db.object(object);
      const result = await ref.query.orderByChild(orderby).equalTo(equalTo).limitToFirst(limit).once(once)
      if (result.exists) {
        return result.val();
      } else {
        return null
        // throw new Error('data not found')
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  async findData(object) {
    try {
      const ref = this.db.object(object);
      const result = await ref.query.once('value')
      if (result.exists) {
        return result.val();
      } else {
        return null
        // throw new Error('data not found')
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  async findDataLimit(object, limit) {
    try {
      const ref = this.db.object(object);
      const result = await ref.query.limitToFirst(limit).once('value')
      if (result.exists) {
        return result.val();
      } else {
        return null
        // throw new Error('data not found')
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  async getDataById() {
    try {

    } catch (error) {
      throw new Error(error)
    }
  }

  async deleteData() {
    try {

    } catch (error) {
      throw new Error(error)
    }
  }

}
