import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { IntroGuard } from './auth/intro.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'info',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule),
    canLoad: [IntroGuard] // Check if we should show the introduction or forward to inside
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule),
    canLoad: [AuthGuard]
  },
  // {
  //   path: 'claims',
  //   loadChildren: () => import('./claims/claims.module').then( m => m.ClaimsPageModule),
  //   canLoad: [AuthGuard]
  // },
  {
    path: 'kills',
    loadChildren: () => import('./kills/kills.module').then( m => m.KillsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then( m => m.EventsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then(m => m.MapPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'manager/team',
    loadChildren: () => import('./manager/team/team.module').then(m => m.TeamPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'logout',
    loadChildren: () => import('./auth/logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'manager/licenses',
    loadChildren: () => import('./manager/licenses/licenses.module').then( m => m.LicensesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'manager/hunt-monitor',
    loadChildren: () => import('./manager/hunt-monitor/hunt-monitor.module').then( m => m.HuntMonitorPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'manager/hunter-profile/:id',
    loadChildren: () => import('./manager/hunter-profile/hunter-profile.module').then( m => m.HunterProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'hunts',
    loadChildren: () => import('./hunts/hunts.module').then( m => m.HuntsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'manager/claims',
    loadChildren: () => import('./manager/claims/claims.module').then( m => m.ClaimsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'hunter-claims',
    loadChildren: () => import('./hunter-claims/hunter-claims.module').then( m => m.HunterClaimsPageModule),
    canLoad: [AuthGuard]
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
