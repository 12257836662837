import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { EmailValidator } from '@angular/forms';
import { BehaviorSubject, from } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { User } from './user.model';
import { Plugins } from '@capacitor/core';

export interface AuthResponseData {
  kind: string;
  idToken: string;
  email: string;
  isManager: boolean;
  refreshToken: string;
  localId: string;
  expiresIn: string;
  registered?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _user = new BehaviorSubject<User>(null);

  get userIsAuthenticated() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return !!user.token;
        } else {
          return false;
        }

      }));
  }

  get userId() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.id;
        } else {
          return null;
        }
      }));
  }

  get userEmail() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.email;
        } else {
          return null;
        }
      }));
  }

  // get isManager() {
  //   return this._user.asObservable().pipe(
  //     map(user => {
  //       console.log('user', user)
  //       if (user) {
  //         return user.isManager || false;
  //       } else {
  //         return null;
  //       }
  //     }));
  // }

  get token() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.token;
        } else {
          return null;
        }
      })
    );
  }

  constructor(private http: HttpClient) { }

  signup(email: string, password: string, isManager: boolean) {
    let fetchedUserId: string;
    let newUser: User;

    return this.http.post<AuthResponseData>(
      `https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=${environment.firebaseAPIKey
      }`,
      { email: email, password: password, returnSecureToken: true, isManager: isManager }
    ).pipe(
      tap(this.setUserData.bind(this)),
      tap(user => {
        this.userId.pipe(
          take(1),
          switchMap(userId => {
            fetchedUserId = userId;
            return this.token;
          }),
          take(1),
          switchMap(token => {
            if (!fetchedUserId) {
              throw new Error('No user found!');
            }
            const expirationTime = new Date(new Date().getTime() + (+user.expiresIn * 1000));
            newUser = new User(user.localId, user.email, user.idToken, expirationTime, isManager);
            return this.http.post<{ name: string }>(
              `https://hunterface-27c9f-default-rtdb.europe-west1.firebasedatabase.app/user.json?auth=${token}`,
              {
                ...newUser
              }
            );
          })
        ).subscribe();
      })
    );
  }

  login(email: string, password: string) {
    return this.http.post<AuthResponseData>(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${environment.firebaseAPIKey
      }`,
      {
        email: email, password: password, returnSecureToken: true
      }).pipe(tap(this.setUserData.bind(this)));
  }

  logout() {
    this._user.next(null);
    Plugins.Storage.remove({ key: 'authData' });
  }

  autoLogin() {
    return from(Plugins.Storage.get({ key: 'authData' })).pipe(
      map(authData => {
        if (!authData || !authData.value) {
          return null;
        }

        const parsedData = JSON.parse(authData.value) as { token: string; tokenExpirationDate: string; userId: string, email: string, isManager: boolean};
        const expirationTime = new Date(parsedData.tokenExpirationDate);
        if (expirationTime <= new Date()) {
          return null;
        }

        const user = new User(parsedData.userId, parsedData.email, parsedData.token, expirationTime, parsedData.isManager);

        return user;
      }),
      tap(user => {

        if (user) {
          this._user.next(user);
        }
      }),
      map(user => {
        return !!user;
      })
    );
  }

  private setUserData(userData: AuthResponseData) {

    const expirationTime = new Date(new Date().getTime() + (+userData.expiresIn * 1000));
    this._user.next(new User(userData.localId, userData.email, userData.idToken, expirationTime, userData.isManager));

    this.storeAuthData(userData.localId, userData.idToken, expirationTime.toISOString(), userData.email, userData.isManager, userData.refreshToken);

  }

  private storeAuthData(userId: string, token: string, tokenExpirationDate: string, email: string, isManager: boolean, refreshToken: string) {
    const data = JSON.stringify({ userId: userId, token: token, tokenExpirationDate: tokenExpirationDate, email: email, isManager: isManager, refreshToken: refreshToken });

    Plugins.Storage.set({ key: 'authData', value: data });
  }
}
