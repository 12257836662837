import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthService } from '../auth/auth.service';
import { RealtimeDbService } from './realtime-db.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private platform: Platform, private authService: AuthService, private realtimeDbService: RealtimeDbService) { }

  isNativePlatform() {
    return this.platform.is('cordova') || this.platform.is('capacitor')
  }

  async userData() {
    try {
      let id: string;
      this.authService.userId.subscribe(userId => id = userId);
      return await this.realtimeDbService.findByChild(id, "user", "id", "value")
    } catch (error) {
      console.log(error)
    }
  }

  async userKey() {
    try {
      const userResult = await this.userData()
      if (userResult) {
        return Object.keys(userResult)[0];
      }
    } catch (error) {
      console.log(error)
    }
  }

  async userId() {
    try {
      const userResult = await this.userData()
      if (userResult) {
        const userKey = Object.keys(userResult)[0];
        return userResult[userKey].id
      }
    } catch (error) {
      console.log(error)
    }
  }

  formatDate(val: string) {
    let date = new Date(val);
    return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
  }

  formatDateExact(val: string) {
    let date = new Date(val);
    let dateStr =
    ("00" + date.getDate()).slice(-2) + "." +
    ("00" + (date.getMonth() + 1)).slice(-2) + "." +
    date.getFullYear() + " " +
    ("00" + date.getHours()).slice(-2) + ":" +
    ("00" + date.getMinutes()).slice(-2) + ":" +
    ("00" + date.getSeconds()).slice(-2);
    return dateStr
  }

  googleMapsMarkerIconPath() {
    return "M12,2.016C13.937,2.016 15.586,2.696 16.945,4.055C18.304,5.414 18.984,7.063 18.984,9C18.984,9.969 18.742,11.078 18.257,12.328C17.772,13.578 17.186,14.75 16.499,15.844C15.812,16.938 15.132,17.961 14.46,18.914C13.788,19.867 13.218,20.624 12.749,21.187L11.999,21.984C11.812,21.765 11.562,21.476 11.249,21.117C10.936,20.758 10.374,20.039 9.561,18.961C8.748,17.883 8.037,16.836 7.428,15.82C6.819,14.804 6.264,13.656 5.764,12.375C5.264,11.094 5.014,9.969 5.014,9C5.014,7.063 5.694,5.414 7.053,4.055C8.412,2.696 10.061,2.016 11.998,2.016L12,2.016Z"
  }

  mapsGoogleapisUrl() {
    return "https://maps.googleapis.com/maps/api/js?key=AIzaSyAXfLeaTDAx8UEJjdfr1J0GzYZKstHrjow"
  }

  openWeatherMapUrl(lat: number, lng: number) {
    // Free plan includes 60 calls per minute
    return `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&units=metric&appid=48e897f53f7425e2a5ace4781bddfd34`
  }

}
