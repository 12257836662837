import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { User } from './auth/user.model';
import { HelperService } from './providers/helper.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { RealtimeDbService } from './providers/realtime-db.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  userEmail: String;
  isManager: boolean;
  private authSub: Subscription;
  private previousAuthState = false;
  hideMenu = true;
  profilePicture: string;
  userKey: string;
  lat: number;
  lng: number;
  isPartOfTeam: boolean = false;

  public watch: any;

  public appPages = [
    { title: 'Dashboad', url: 'info', icon: 'information-circle' },
    { title: 'My Claims', url: 'hunter-claims', icon: 'mail' },
    { title: 'My Hunts', url: 'hunts', icon: 'skull' },
    { title: 'Licenses', url: 'manager/licenses', icon: 'document' },
    { title: 'Hunting Team', url: 'manager/team', icon: 'people-circle' },
    // { title: 'Profile', url: 'user/profile', icon: 'person' },
    // { title: 'Profile Wizard', url: 'user/profile-wizard', icon: 'person-circle' },
    // { title: 'Hunter Card Temp', url: 'user/hunter-card', icon: 'person-circle' },
    // { title: 'Map', url: 'map', icon: 'navigate-circle' }
  ];

  public managerPages = [
    { title: 'Dashboad', url: 'info', icon: 'information-circle' },
    { title: 'My Claims', url: 'hunter-claims', icon: 'mail' },
    { title: 'My Hunts', url: 'hunts', icon: 'skull' },
    { title: 'Claims', url: 'manager/claims', icon: 'file-tray-full' },
    { title: 'Hunt Monitoring', url: 'manager/hunt-monitor', icon: 'eye' },
    { title: 'Licenses', url: 'manager/licenses', icon: 'document' },
    { title: 'Hunting Team', url: 'manager/team', icon: 'people-circle' }
  ];

  public footerPages = [
    //{ title: 'Settings', url: 'settings', icon: 'settings' },
    { title: 'Logout', url: 'logout', icon: 'power' },
  ];

  constructor(private authService: AuthService, private router: Router, private helperService: HelperService, private geolocation: Geolocation, private realtimeDbService: RealtimeDbService) { }

  ngOnInit() {

    this.authSub = this.authService.userIsAuthenticated.subscribe(isAuth => {

      this.userData().then(()=>{
        this.startWatch()
      })

      if (!isAuth && this.previousAuthState !== isAuth) {
        this.router.navigateByUrl('/auth');
      }

      if (isAuth) {
        this.hideMenu = false;
      }
      this.previousAuthState = isAuth;
      console.log('auth:'+isAuth);
    });

    let fetcheduserEmail: string;

    this.authService.userId.subscribe(
      userId => {
        console.log('userid:'+userId);
      }
    );

    this.authService.userEmail.subscribe(
      userEmail => {
        this.userEmail = userEmail;
      }
    );

  }

  startWatch() {
    if (!this.watch) {
      console.log('watch activated')
      this.watch = this.geolocation.watchPosition().subscribe((data) => {
        if ("coords" in data) {
          if (data.coords.latitude != this.lat || data.coords.longitude != this.lng) {
            // this.centerMap(data.coords.latitude, data.coords.longitude);
            this.updateGeo(data.coords.latitude, data.coords.longitude).then(()=>{
              console.log('coords updated')
            })
          }
        }
      });
    }
  }

  stopWatch() {
    if (this.watch) {
      this.watch.unsubscribe();
      this.watch = null;
      console.log('watch stopped')
    }
  }

  async userData() {
    try {
      const result = await this.helperService.userData()
      if (result) {
        this.userKey = Object.keys(result)[0]
        if (result[this.userKey].geoLocation) {
          this.lat = result[this.userKey].geoLocation.lat
          this.lng = result[this.userKey].geoLocation.lng
        }
        if (result[this.userKey].profilePicture) {
          this.profilePicture = result[this.userKey].profilePicture.imageUrl
        }
        this.isManager = false
        if (result[this.userKey].isManager) {
          this.isManager = result[this.userKey].isManager
        }
        // console.log('isManager', this.isManager)
        if (result[this.userKey].id) {
          await this.checkTeam(result[this.userKey].id)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async checkTeam(id: string) {
    try {
      const teamResult = await this.realtimeDbService.findByChild(id, "team", `userId/${id}`, "value")
      if (teamResult) {
        this.isPartOfTeam = true
      } else {
        this.isPartOfTeam = false
      }
      console.log('isPartOfTeam', this.isPartOfTeam)
    } catch (error) {
      console.log(error)
    }
  }

  async updateGeo(lat: number, lng: number) {
    try {
      const userKey = await this.helperService.userKey()
      await this.realtimeDbService.updateData(`user/${userKey}`, {
        geoLocation: {
          lat: lat,
          lng: lng,
          timestamp: (new Date()).toISOString()
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

}
